<template>
  <div>
    <transition :name="transitionEffect">
      <div class="carousel-item">
        <div class="Acc-texte">
          <div class="selectors-container">
            <div class="select">
              <div @click="toggleSelect('famille')" class="button">
                <span v-if="!selectedFamille.nom_famille_produit">
                  {{ $t("prix.select") }}
                </span>
                <span v-else>
                  {{ selectedFamille.nom_famille_produit }}
                </span>
                <span
                  class="icon material-symbols-outlined"
                  :class="showFamille ? 'close' : ''"
                >
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
              </div>
              <div :class="`select-block ${showFamille ? 'open' : ''}`">
                <div class="scroll">
                  <ul>
                    <li
                      v-for="item in itemsFamille"
                      :key="item.id_nom_famille_produit"
                      @click="clickItemFamille(item)"
                      :class="
                        item.nom_famille_produit ===
                        selectedFamille.nom_famille_produit
                          ? 'active'
                          : ''
                      "
                    >
                      <p
                        @click="
                          $emit('ClickItemFamille', item.nom_famille_produit)
                        "
                      >
                        {{ item.nom_famille_produit }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="showFamille"
                @click="toggleSelect('famille')"
                class="select-wrapper"
              ></div>
            </div>
            <!-- Select for "Marché" -->

            <div class="select">
              <div @click="toggleSelect('marche')" class="button">
                <span v-if="!selectedMarche.nom_produit">
                  {{ $t("prix.select_produit") }}
                </span>
                <span v-else>
                  {{ selectedMarche.nom_produit }}
                </span>
                <span
                  class="icon material-symbols-outlined"
                  :class="showMarche ? 'close' : ''"
                >
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
              </div>
              <div :class="`select-block ${showMarche ? 'open' : ''}`">
                <div class="scroll">
                  <!-- Champ de recherche -->
                  <input
                    type="text"
                    v-model="searchTermMarche"
                    @input="filterMarches"
                    placeholder="Rechercher un produit"
                    class="search-input"
                  />

                  <ul>
                    <li
                      v-for="item in filteredMarches"
                      :key="item.id_produit"
                      @click="clickItemMarche(item)"
                      :class="
                        item.nom_produit === selectedMarche.nom_produit
                          ? 'active'
                          : ''
                      "
                    >
                      <p @click="$emit('ClickItemMarche', item.nom_produit)">
                        {{ item.nom_produit }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                v-if="showMarche"
                @click="toggleSelect('marche')"
                class="select-wrapper"
              ></div>
            </div>

            <!-- Select for "Produits" -->
            <div class="select">
              <div @click="toggleSelect('produit')" class="button">
                <span v-if="!selectedProduit.marche">
                  {{ $t("prix.select_marche") }}
                </span>
                <span v-else>
                  {{ selectedProduit.marche }}
                </span>
                <span
                  class="icon material-symbols-outlined"
                  :class="showProduit ? 'close' : ''"
                >
                  <i class="fa-solid fa-chevron-down"></i>
                </span>
              </div>

              <!-- Affiche un message d'erreur si hasError est vrai -->
              <div v-if="hasError" class="error-message">
                {{ errorMessage }}
              </div>

              <!-- Liste déroulante des marchés -->
              <div :class="`select-block ${showProduit ? 'open' : ''}`" v-else>
                <div class="scroll">
                  <ul>
                    <!-- Message si aucun marché disponible -->
                    <span v-if="itemsProduit.length === 0"
                      >Aucun marché disponible pour ce produit</span
                    >
                    <!-- Liste des marchés -->
                    <li
                      v-for="item in itemsProduit"
                      :key="item.id_marche"
                      @click="clickItemProduit(item)"
                      :class="
                        item.marche === selectedProduit.marche ? 'active' : ''
                      "
                    >
                      <p @click="$emit('ClickItemProduit', item.marche)">
                        {{ item.marche }}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Wrapper pour fermer le select -->
              <div
                v-if="showProduit"
                @click="toggleSelect('produit')"
                class="select-wrapper"
              ></div>
            </div>
          </div>
          <div v-if="resultatSearch.length > 0" class="tableau">
            <section class="table__body">
              <table>
                <thead>
                  <tr>
                    <th rowspan="2">Produit</th>
                    <th colspan="2">Actuel</th>
                    <th colspan="2">Précédent</th>
                    <th rowspan="2">Évolution (%)</th>
                  </tr>
                  <tr>
                    <th>Prix (GNF)</th>
                    <th>Date</th>
                    <th>Prix (GNF)</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ nomProduit }}</td>
                    <td>
                      {{ formatBudget(prix_actuel) }}
                    </td>
                    <td>
                      {{ date_actuel }}
                    </td>
                    <td>
                      <template v-if="resultatSearch.length > 1">
                        {{ formatBudget(prix_precedent) }}
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td>
                      <template v-if="resultatSearch.length > 1">
                        {{ date_precedent }}
                      </template>
                      <template v-else>-</template>
                    </td>
                    <td>
                      <template v-if="resultatSearch.length > 1">
                        <span
                          :class="{
                            'text-green':
                              getDifference(prix_precedent, prix_actuel) < 0,
                            'text-red':
                              getDifference(prix_precedent, prix_actuel) > 0,
                          }"
                        >
                          <!-- Affiche la flèche et le pourcentage -->

                          <i
                            :class="{
                              'fa fa-arrow-down':
                                getDifference(prix_precedent, prix_actuel) < 0,
                              'fa fa-arrow-up':
                                getDifference(prix_precedent, prix_actuel) > 0,
                            }"
                          ></i>
                          {{
                            formatBudget(
                              getDifference(prix_precedent, prix_actuel)
                            )
                          }}%
                        </span>
                      </template>
                      <template v-else>-</template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
          <div v-if="resultatSearch.length > 0" class="selectors-container">
            <img v-if="image" :src="image" alt="Image du produit" />
            <p v-else>Aucune image disponible</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axiosClient from "@/axiosClient";

export default {
  watch: {},
  name: "CptSelect",
  data() {
    return {
      showFamille: false,
      showMarche: false,
      showProduit: false,
      selectedFamille: {},
      selectedMarche: {},
      selectedProduit: {},
      resultatSearch: [],
      itemsFamille: [],
      nomProduit: "",
      nomMarche: "",
      nomPrefecture: "",
      prix_actuel: null,
      date_actuel: null,
      prix_precedent: null,
      date_precedent: null,
      itemsMarche: [],
      itemsProduit: [],
      element: false,
      hasError: false,
      image: null,
      searchTermMarche: "",
      filteredMarches: [],
    };
  },
  mounted() {
    this.fetchFamilles();
  },
  methods: {
    toggleSelect(type) {
      if (type === "famille") {
        this.showFamille = !this.showFamille;
        this.showMarche = false;
        this.showProduit = false;
      } else if (type === "marche") {
        this.showMarche = !this.showMarche;
        this.showFamille = false;
        this.showProduit = false;
      } else if (type === "produit") {
        this.showProduit = !this.showProduit;
        this.showMarche = false;
        this.showFamille = false;
      }
    },
    clickItemFamille(item) {
      this.selectedMarche = {};
      this.selectedProduit = {};
      this.itemsMarche = [];
      this.itemsProduit = [];

      // Appeler l'API pour récupérer les marchés en fonction de la famille sélectionnée
      if (item.id_famille_produit) {
        this.fetchProduits(item.id_famille_produit);
      }

      // Mettre à jour la famille sélectionnée et fermer la liste
      this.selectedFamille = item;
      this.showFamille = false; // Fermer la liste déroulante
    },
    clickItemMarche(item) {
      this.selectedMarche =
        this.selectedMarche.nom_produit === item.nom_produit ? {} : item;
      //console.log("produit", this.selectedMarche);
      this.fetchMarches(item.code_produit);
      this.showMarche = false;
    },
    clickItemProduit(item) {
      this.selectedProduit =
        this.selectedProduit.marche === item.marche ? {} : item;
      this.showProduit = false;
      this.onSearchClick();
    },
    async fetchFamilles() {
      try {
        const response = await axiosClient.get(
          "/parametrages/familles/sites/famille-enabled"
        );
        this.itemsFamille = response.data;
      } catch (error) {
        this.element = !this.element;
        console.error("Erreur lors de la récupération des familles", error);
      }
    },
    filterMarches() {
      this.filteredMarches = this.itemsMarche.filter((item) =>
        item.nom_produit
          .toLowerCase()
          .includes(this.searchTermMarche.toLowerCase())
      );
    },
    async fetchProduits(idFamilleProduit) {
      try {
        const response = await axiosClient.get(
          `/parametrages/produits/sites/produits-enabled`,
          {
            params: {
              id_famille_produit: idFamilleProduit,
            },
          }
        );
        this.hasError = false;
        this.itemsMarche = response.data; // Mettre à jour la liste des marchés
        this.filteredMarches = this.itemsMarche; // Initialiser les marchés filtrés
      } catch (error) {
        console.error("Erreur lors de la récupération des marchés", error);
      }
    },
    async fetchMarches(idFamilleProduit) {
      try {
        const response = await axiosClient.get(
          `/parametrages/marches/listes-par-produit/${idFamilleProduit}`
        );
        this.itemsProduit = response.data;
        this.hasError = false;
      } catch (error) {
        if (error.response && error.response.status === 500) {
          this.errorMessage = "Aucun marché disponible";
        } else {
          this.errorMessage =
            error.response?.data?.detail ||
            "Une erreur s'est produite lors de la récupération des marchés.";
        }
        this.hasError = true;
        this.itemsProduit = [];
        console.error("Erreur lors de la récupération des marchés", error);
      }
    },

    onSearchClick() {
      if (!this.selectedProduit || !this.selectedMarche) {
        alert(
          "Veuillez sélectionner un produit et un marché avant de rechercher."
        );
        return;
      }

      const idMarche = this.selectedProduit.id_marche;
      const codeProduit = this.selectedMarche.code_produit;

      console.log(this.selectedMarche);

      if (!codeProduit || !idMarche) {
        alert("Produit ou marché manquant.");
        return;
      }

      const apiUrl = `/enquetes/marches-prix/prix-des-deux-dernieres/${codeProduit}/${idMarche}`;

      axiosClient
        .get(apiUrl)
        .then((response) => {
          this.resultatSearch = response.data;
          this.nomProduit = response.data[0].produit;
          this.nomMarche = response.data[0].marche;
          this.nomPrefecture = response.data[0].prefecture;
          this.image = response.data[0].image;
          if (response.data.length > 1) {
            console.log("yes");

            this.prix_actuel = response.data[0].prix_kg;
            this.date_actuel = response.data[0].date_enquete;
            this.prix_precedent = response.data[1].prix_kg;
            this.date_precedent = response.data[1].date_enquete;
          } else {
            console.log("nn");
            this.prix_actuel = response.data[0].prix_kg;
            this.date_actuel = response.data[0].date_enquete;
          }
        })
        .catch((error) => {
          console.error("Erreur lors de l'appel à l'API :", error);
          alert("Une erreur est survenue lors de la recherche.");
        });
    },
    formatBudget(value) {
      let Formatter = new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return Formatter.format(value);
    },
    dateFormat(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = String(d.getFullYear());

      return `${day}/${month}/${year}`;
    },
    getDifference(ancien, nouveau) {
      if (
        ancien === undefined ||
        nouveau === undefined ||
        ancien === 0 ||
        nouveau === 0
      ) {
        return 0;
      } else {
        const difference = parseFloat(nouveau) - parseFloat(ancien);
        return ((difference / parseFloat(ancien)) * 100).toFixed(2);
      }
    },
  },
};
</script>

<style scoped>
.carousel-item {
  position: relative;
  height: 50%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  background-color: var(--blanc);
}

.Acc-texte {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100;
  width: 90%;
  color: var(--noir);
  height: 100%;
  text-align: center;
  padding: 5px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.selectors-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 20px;
  width: 40%;
  align-items: flex-start;
}

.error-message {
  color: red;
  text-align: center;
}
.search-input {
  width: 100%;

  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  transition: border-color 0.3s;
  margin-bottom: 10px;
}

.search-input:focus {
  border-color: #00a36c;
  outline: none;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
}

.select {
  padding: 5px;
  font-size: 16px;
}
.animated {
  transition: color 0.5s ease, transform 0.5s ease;
}
.text-red {
  color: red;
}
.text-green {
  color: green;
}

.tableau {
  width: 100%;
  box-shadow: var(--shadow-small);
  background-color: var(--blanc);
}

.title {
  text-align: left;
  font-weight: bold;
}

.table__body {
  max-height: 600px;
  overflow-y: auto;
  /* Permettre le défilement vertical si le contenu dépasse */
  margin: 0.5rem auto;
}

.table__header {
  padding: 10px 5px 0;
}

.table__header h1 {
  font-size: 28px;
  font-weight: bold;
  color: red;
}

.carousel-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.select {
  width: 100%;
  position: relative;
  color: black;
}
.select .select-block .scroll {
  height: 100%;
  overflow-y: auto;
}

.select .button {
  background-color: #f4f5fa;
  padding: 15px;
  color: black;
  cursor: pointer;
}

.select .select-block {
  width: 100%;
  box-shadow: 0px 0px 10px #8888884f;
  position: absolute;
  background-color: var(--blanc);
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  z-index: 102;
  top: 64px;
}

.select .select-block.open {
  visibility: visible;
  opacity: 1;
}

.select ul li {
  list-style-type: none;
  cursor: pointer;
}

.select ul {
  margin: 0;
  padding: 0 10px;
}

.select ul li p:hover {
  padding: 10px 5px;
  background-color: hsl(260deg 11% 95% / 70%);
}

.select ul li p {
  padding: 5px 0;
  width: 100%;
}

.select .icon {
  position: absolute;
  right: 10px;
  top: 15px;
  transition: all 0.3s;
}

.select .icon.close {
  transform: rotate(180deg);
}
.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 0.5rem;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2;
}

.carousel-control.left {
  left: 0;
}

.carousel-control.right {
  right: 0;
}

thead th {
  padding: 6px;
  border: 1px solid;
}

table,
th,
td {
  padding: 7px;
  font-size: 16px;
}

thead th,
tbody td {
  padding: 10px;
  border: 1px solid #ccc;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 20px;
}

.carousel-item .image {
  position: absolute;
  top: 27%;
  right: -16%;
  transform: translate(-50%, -50%);
  width: 32%;
  height: 88%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectors-container img {
  width: 220px;
  border: 5px solid hsla(240, 7%, 97%, 1);
  height: 220px;
}

.slide-in-enter-active,
.slide-in-leave-active,
.slide-out-enter-active,
.slide-out-leave-active {
  transition: all 1s ease-in-out;
}

.slide-in-enter-from {
  transform: translateX(-100%);
}

.slide-in-leave-to {
  transform: translateX(100%);
}

.slide-out-enter-from {
  transform: translateX(100%);
}

.slide-out-leave-to {
  transform: translateX(-100%);
}

@media (max-width: 1000px) {
  td:not(:first-of-type) {
    min-width: 7rem;
  }
}

@media (max-width: 960px) {
  td:not(:first-of-type) {
    min-width: 4rem;
  }
}

@media (max-width: 800px) {
  .carousel-item .image {
    display: none;
  }

  .Acc-texte {
    width: 100%;
  }
}

@media (max-width: 600px) {
  td:not(:first-of-type) {
    min-width: 0rem;
  }

  table,
  th,
  td {
    font-size: 12px;
    /* Réduire la taille du texte pour les petits écrans */
  }

  .table__body {
    max-height: 300px;
    /* Ajuster la hauteur pour les petits écrans */
  }
}
</style>
