<template>
  <div>
    <div class="content">
      <h2>{{ $t("description.titre") }}</h2>
      <div class="accordion">
        <!-- Premier accordéon -->
        <div class="accordion-item" @click="toggleAccordion(1)">
          <div class="accordion-header">
            <h4 style="color: #139864">
              <i class="fa-solid fa-seedling fa-1x"></i>
              {{ $t("description.vision_titre") }}
            </h4>

            <i
              :class="
                isAccordionOpen(1)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(1) }"
            v-if="isAccordionOpen(1)"
          >
            <p>Aucune donnée disponible!</p>
          </div>
        </div>

        <!-- Deuxième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(2)">
          <div class="accordion-header">
            <h4 style="color: #139864">
              <i class="fa-solid fa-cow fa-1x"></i>
              {{ $t("description.mission_titre") }}
            </h4>
            <i
              :class="
                isAccordionOpen(2)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(2) }"
            v-if="isAccordionOpen(2)"
          >
            <table>
              <thead>
                <tr>
                  <th>Indicateur</th>
                  <th>Valeur Cible</th>
                  <th>Valeur Réalisée</th>
                  <th>Taux</th>
                  <th>Période</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in elevages" :key="item.id">
                  <td>{{ item.indicateur }}</td>
                  <td>{{ item.valeur_cible }}</td>
                  <td>{{ item.valeur_realise }}</td>
                  <td>{{ item.taux }}%</td>
                  <td>{{ item.periode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Troisième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(3)">
          <div class="accordion-header">
            <h4 style="color: #4f8ff5">
              <i class="fa-solid fa-fish fa-1x"></i>
              {{ $t("description.valeur_titre") }}
            </h4>
            <i
              :class="
                isAccordionOpen(3)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(3) }"
            v-if="isAccordionOpen(3)"
          >
            <table>
              <thead>
                <tr>
                  <th>Indicateur</th>
                  <th>Valeur Cible</th>
                  <th>Valeur Réalisée</th>
                  <th>Taux</th>
                  <th>Période</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in peches" :key="item.id">
                  <td>{{ item.indicateur }}</td>
                  <td>{{ formatNumber(item.valeur_cible) }}</td>
                  <td>{{ formatNumber(item.valeur_realise) }}</td>
                  <td>{{ item.taux }}%</td>
                  <td>{{ item.periode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Quatrième accordéon -->
        <div class="accordion-item" @click="toggleAccordion(4)">
          <div class="accordion-header">
            <h4 style="color: #ffa800">
              <i class="fa-solid fa-globe fa-1x"></i>
              {{ $t("description.environnement") }}
            </h4>
            <i
              :class="
                isAccordionOpen(4)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(4) }"
            v-if="isAccordionOpen(4)"
          >
            <table>
              <thead>
                <tr>
                  <th>Indicateur</th>
                  <th>Valeur Cible</th>
                  <th>Valeur Réalisée</th>
                  <th>Taux</th>
                  <th>Période</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in environnements" :key="item.id">
                  <td>{{ item.indicateur }}</td>
                  <td>{{ formatNumber(item.valeur_cible) }}</td>
                  <td>{{ formatNumber(item.valeur_realise) }}</td>
                  <td>{{ item.taux }}%</td>
                  <td>{{ item.periode }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "CptDescription",

  data() {
    return {
      openAccordion: null, // Stocke l'état des accordéons
      elevages: [],
      peches: [],
      environnements: [],
      accessToken: null,
    };
  },

  methods: {
    toggleAccordion(index) {
      // Permet d'ouvrir ou de fermer un accordéon
      this.openAccordion = this.openAccordion === index ? null : index;
    },

    async fetchAccessToken() {
      try {
        const response = await axios.post("https://sim-guinee.org/api/login", {
          username: "demo",
          password: "123456789",
        });
        this.accessToken = response.data.access_token;
        await this.fetchStatData(); // Appeler l'API des statistiques une fois le token récupéré
      } catch (error) {
        console.error("Erreur lors de l’authentification:", error);
      }
    },

    formatNumber(value) {
      if (!value && value !== 0) return "";
      return value.toLocaleString("fr-FR");
    },

    async fetchStatData() {
      if (!this.accessToken) return;

      try {
        const response = await axios.get(
          "https://sim-guinee.org/api/statistics-chiffre-cle?statut=true",
          {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          }
        );

        if (response.data && response.data.length > 0) {
          // Filtrer et regrouper les données par secteur
          const groupedData = response.data.reduce((acc, item) => {
            if (item.visible) {
              // Initialiser un tableau pour chaque secteur s'il n'existe pas encore
              if (!acc[item.secteur]) {
                acc[item.secteur] = [];
              }
              // Ajouter l'élément dans le tableau du secteur correspondant
              acc[item.secteur].push(item);
            }
            return acc;
          }, {});

          // Stocker les données regroupées dans l'état ou les données du composant
          this.statDataBySector = groupedData;
          this.elevages = this.statDataBySector.ELEVAGE;
          this.peches = this.statDataBySector.PECHE;
          this.environnements = this.statDataBySector.ENVIRONNEMENT;
          // console.log("tableau", this.statDataBySector.ELEVAGE);
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des statistiques:",
          error
        );
      }
    },

    isAccordionOpen(index) {
      // Vérifie si l'accordéon est ouvert
      return this.openAccordion === index;
    },
  },
  async mounted() {
    await this.fetchAccessToken();
  },
};
</script>

<style lang="css" scoped>
.content {
  max-width: var(--max-width);
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
table tbody td {
  font-weight: bold;
}

.content h2 {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  text-transform: uppercase;
  color: var(--vert);
}
.accordion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Alignement en haut pour que chaque accordéon garde sa propre hauteur */
}

.accordion-item {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.accordion-item:hover {
  background-color: #f0f0f0;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header h4 {
  margin-left: 20px;
  font-size: x-large;
}

.accordion-content {
  padding: 15px;
  border-top: 1px solid #ddd;
  max-height: 0; /* Hauteur initiale fermée */
  overflow: hidden; /* Masquer le contenu quand l'accordéon est fermé */
  transition: max-height 0.3s ease; /* Transition pour l'animation de l'ouverture/fermeture */
}

.accordion-content.open {
  max-height: 500px; /* Hauteur maximum lors de l'ouverture, à ajuster selon ton contenu */
}

.stat-card {
  display: grid;
  gap: 15px;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.full-width {
  grid-column: span 3; /* Fait en sorte que l'indicateur occupe toute la ligne */
}

.stat-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none; /* Désactive le clic */
}

.icon {
  font-size: 24px;
  color: #139864;
  margin-right: 15px;
}

.stat-text {
  display: flex;
  flex-direction: column;
}

.stat-label {
  font-size: 14px;
  font-weight: bold;
  color: #666;
  margin: 0;
}

.stat-value {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin: 0;
}

@media (max-width: 768px) {
  .accordion-item {
    width: 100%;
  }
  .stat-card {
    grid-template-columns: 1fr; /* Passage en une seule colonne sur petit écran */
  }
}
</style>
