<template>
  <div class="container">
    <div class="magasin-content">
      <div class="texte">
        <div class="select">
          <input
            class="effect-6"
            type="text"
            v-bind:placeholder="$t('magasin.placeholder')"
          />
          <span class="focus-border"></span>
        </div>
        <div class="accordion-item" @click="toggleAccordion(1)">
          <div class="accordion-header">
            <h5 style="color: #139864">
              <i class="fa-solid fa-store fa-1x"></i>
              {{ $t("magasin.sous_titre1") }}
            </h5>

            <i
              :class="
                isAccordionOpen(1)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(1) }"
            v-if="isAccordionOpen(1)"
          >
            <div
              class="select"
              v-for="(marker, index) in magasinMarkers"
              :key="index"
              @click="showPopup(marker)"
            >
              <div @click="toggleSelect(index)" class="button">
                <span> {{ marker.nom_magasin }}</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="accordion-item" @click="toggleAccordion(2)">
          <div class="accordion-header">
            <h5 style="color: #139864">
              <i class="fa-solid fa-basket-shopping fa-1x"></i>
              {{ $t("magasin.sous_titre2") }}
            </h5>

            <i
              :class="
                isAccordionOpen(1)
                  ? 'fa-solid fa-chevron-up'
                  : 'fa-solid fa-chevron-down'
              "
            ></i>
          </div>
          <div
            class="accordion-content"
            :class="{ open: isAccordionOpen(2) }"
            v-if="isAccordionOpen(2)"
          >
            <div
              class="select"
              v-for="(marker, index) in marcheMarkers"
              :key="index"
              @click="showPopup(marker)"
            >
              <div @click="toggleSelect(index)" class="button">
                <span> {{ marker.nom_marche }}</span>
              </div>
            </div>
          </div>
        </div> -->
       
        <!-- <div style="text-align: center;"><span style="color: black;">{{ $t('magasin.sous_titre') }}</span></div>
        <div class="select" v-for="(marker, index) in markers" :key="index" @click="showPopup(marker)">
          <div @click="toggleSelect(index)" class="button">
            <span> {{ marker.nom_magasin }}</span>
          </div>
        </div> -->
      </div>
      <div class="maps_container">
        <div class="map-wrap">
          <a href="https://www.maptiler.com" class="watermark">
            <img
              src="https://api.maptiler.com/resources/logo.svg"
              alt="MapTiler logo"
            />
          </a>
          <div class="map" ref="mapContainer"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Map, NavigationControl, Marker, Popup } from "maplibre-gl";
import { shallowRef, onMounted, onUnmounted, markRaw } from "vue";
import axiosClient from "@/axiosClient";

export default {
  name: "CPtMagasin",

  data() {
    return {
      show: false,
      magasins: "",
      marker: "",
      openAccordion: null,
    };
  },

  setup() {
    const mapContainer = shallowRef(null);
    const map = shallowRef(null);
    const magasinMarkers = shallowRef([]);
    const marcheMarkers = shallowRef([]);

    onMounted(async () => {
      const apiKey = "R0tHx9tGeRGXSyvwlX0q";
      const initialState = { lng: -10.728084, lat: 10.722622, zoom: 5.8 };

      // Initialiser la carte
      map.value = markRaw(
        new Map({
          container: mapContainer.value,
          style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${apiKey}`,
          center: [initialState.lng, initialState.lat],
          zoom: initialState.zoom,
          dragPan: false,
        })
      );
      map.value.addControl(new NavigationControl(), "top-right");

      // Requête pour les magasins
      const magasinResponse = await axiosClient.get("/parametrages/magasins");
      magasinMarkers.value = magasinResponse.data;

      // Ajouter des marqueurs pour les magasins
      magasinMarkers.value.forEach((marker) => {
        marker.show = false;
        if (marker.latitude && marker.longitude) {
          var longitude = parseFloat(marker.longitude);
          var latitude = parseFloat(marker.latitude);
          if (typeof longitude === "number" && typeof latitude === "number") {
        

            const newMarker = new Marker({ color: "#FF0000" })
              .setLngLat([longitude, latitude])
              .addTo(map.value);

            const popupContent = `
                <div style="width:250px;font-size:14px">
                    <h3> Nom  magasin :  ${marker.nom_magasin}</h3>
                    <h3> Capacité :  ${marker.capacite}</h3>
                    <h4>Description: ${marker.description}</h4> 
                </div>
                `;
            newMarker.setPopup(new Popup().setHTML(popupContent));
          }
        }
      });

      //Requête pour les marchés
       const marcheResponse = await axiosClient.get("/parametrages/marches/marche/listes?type_marche=4");
       marcheMarkers.value = marcheResponse.data;

       console.log("marchés",marcheResponse.data);
      
      // // Ajouter des marqueurs pour les marchés
      //  marcheMarkers.value.forEach((marker) => {
      //    marker.show = false;
      //    if (marker.latitude && marker.longitude) {
      //      var longitude = parseFloat(marker.longitude);
      //      var latitude = parseFloat(marker.latitude);
      //      if (typeof longitude === "number" && typeof latitude === "number" ) {
            
      //        if(latitude>0 && longitude<0)

      //        {
      //            const newMarker1 = new Marker({ color: "#00FF00" }) // Utilise une autre couleur pour différencier les marchés
      //          .setLngLat([longitude, latitude])
      //          .addTo(map.value);

      //        const popupContent = `
      //            <div style="width:250px;font-size:14px">
      //                <h3> Nom  marché :  ${marker.nom_marche}</h3> 
      //            </div>
      //            `;
      //        newMarker1.setPopup(new Popup().setHTML(popupContent));
      //        }
          

          
      //      }
      //    }
      //  });
    });

    onUnmounted(() => {
      map.value?.remove();
    });

    function showPopup(marker) {
      if (marker.longitude && marker.latitude) {
        var longitude = parseFloat(marker.longitude);
        var latitude = parseFloat(marker.latitude);
        if (typeof longitude === "number" && typeof latitude === "number") {
          const coordinates = [longitude, latitude];
          map.value.flyTo({ center: coordinates, zoom: 8 });
          new Popup()
            .setLngLat(coordinates)
            .setHTML(
              `<div style="width:250px;font-size:14px; " ><h3>Magasin ${marker.nom_magasin}</h3><p><h4>Description:</h4> ${marker.description}</p></div>`
            )
            .addTo(map.value);
          if (map.value.getZoom() > 16) {
            map.value.zoomTo(16, { duration: 500, center: coordinates });
          }
        }
      }
    }

    function toggleSelect(index) {
      magasinMarkers.value[index].show = !magasinMarkers.value[index].show;
    }

    return {
      map,
      mapContainer,
      magasinMarkers,
      marcheMarkers,
      showPopup,
      toggleSelect,
    };
  },
  methods: {
    toggleAccordion(index) {
      // Permet d'ouvrir ou de fermer un accordéon
      this.openAccordion = this.openAccordion === index ? null : index;
    },

    isAccordionOpen(index) {
      // Vérifie si l'accordéon est ouvert
      return this.openAccordion === index;
    },
  },
};
</script>

<style scoped>
.magasin-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  box-shadow: var(--box-shadow);
}
.accordion {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Alignement en haut pour que chaque accordéon garde sa propre hauteur */
}

.accordion-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin: 10px 0;
  padding: 10px;
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.accordion-item:hover {
  background-color: #f0f0f0;
}

.accordion-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accordion-header h5 {
  margin-left: 20px;
  font-size: 16px;
}

.accordion-content {
  padding: 15px;
  border-top: 1px solid #ddd;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.accordion-content.open {
  color: black;
  max-height: 500px;
}
.fa-chevron-down:before {
  content: "\f078";
  color: #139864;
}
.magasin-content .texte {
  width: 98%;
  max-width: 290px;
  height: 450px;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 10px;
  color: var(--blanc);
  overflow-y: scroll;
}
.mapboxgl-popup-content {
  top: -20 !important;
}

.magasin-content .texte .texte-content {
  justify-content: flex-start;
  padding: 10px;
  align-items: center;
}

@media (max-width: 768px) {
  .magasin-content {
    max-width: 600px;
    width: 90%;
    flex-direction: column;
  }

  .magasin-content .texte {
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
  }
}

input[type="text"] {
  color: #333;
  width: 100%;
  padding: 12px 10px;
  border-radius: 50px;
  font-size: 14px;
  outline: 0;
  border: none;
  box-shadow: var(--shadow-small);
}

:focus {
  outline: none;
}

.maps_container {
  width: 60%;
  height: 450px;
}


@import "~maplibre-gl/dist/maplibre-gl.css";

.map-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}

.map {
  position: relative;
  width: 100%;
  height: 100%;
}

.watermark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 999;
}

.mapboxgl-popup,
.maplibregl-popup {
  top: -26px !important;
}

.mapboxgl-popup-close-button,
.maplibregl-popup-close-button {
  border: none !important;
  right: 4px !important;
}

.select {
  text-align: left;
  width: 100%;
  position: relative;
  max-width: 500px;
}

.select .button {
  position: relative;
  width: 100%;
  background-color: var(--blanc);
  padding: 15px 20px;
  font-size: 14px;
  color: #5f6368ff;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: var(--shadow-small);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-top: 15px;
}
.fa-chevron-up:before {
  content: "\f077";
  color: #139864;
}
.select .select-block {
  width: 100%;
  position: relative;
  background-color: var(--blanc);
  opacity: 0;
  transition: all 0.3s;
  display: none;
  box-shadow: var(--shadow-small);
  color: #5f6368ff;
}

.select .select-block .scroll {
  overflow-y: auto;
}

.select .select-block.open {
  display: block;
  opacity: 1;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
@media (max-width: 768px) {
  .accordion-item {
    width: 100%;
  }
}
</style>
